export const validLibIds = ["lib-1", "lib-2", "lib-3"];

export const publicationList = [
  {
    libId: "lib-1",
    title:
      "BIPP-ISB Formalises the Relationship with Srishti Manipal Institute of Art, Design and Technology",
    description:
      "The major objective of the partnership is to facilitate the exchange of information on research and educational programs,teaching materials, and literature relevant to their educational and research activities. It also involves jointly organizing short-term education programs, seminars, conferences, and workshops on mutual interests, with invitations extended to each other’s faculty for participation.....",
    hashTags: ["knowledgepartnership"],
    url: "/library?libId=lib-1",
  },
  {
    libId: "lib-2",
    title: "Collaboration for Himachal Pradesh Scientific Forest Inventory",
    description:
      "At the heart of this collaboration lies a vision of sustainability and prosperity. The primary objective is to conduct a comprehensive inventory of selected forest products, assessing their distribution and abundance with precision. This endeavor is not just about mapping resources; it’s a strategic step toward realizing the potential revenue from the sustainable management and regulation of these natural treasure.",
    hashTags: ["Forestdepartment", "Forestinventorymapping"],
    url: "/library?libId=lib-2",
  },
  {
    libId: "lib-3",
    title:
      "BiofuelCircle and BIPP-ISB Collaborate to Strengthen Forest-Based Bioenergy Value Chains ",
    description:
      "This partnership aims to utilize forests as sustainable sources of raw materials for the bioenergy sector and empower forest-based community-owned enterprises with secure tenure.",
    hashTags: ["Bioenergymission"],
    url: "/library?libId=lib-3",
  },
];
