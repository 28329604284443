import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrow from "../../assests/images/left-arrow-white.png";
import leftBrownArrow from "../../assests/images/leftArrow.png";
import rightArrow from "../../assests/images/right-arrow-white.png";
import rightBrownArrow from "../../assests/images/rightArrow.png";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { publicationList } from "../../utils/library-data/publicationList";

function PrevArrow(props) {
  const { className, style, onClick, detail } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginLeft: "-25px" }}
      onClick={onClick}
    >
      <img
        src={detail ? leftBrownArrow : leftArrow}
        alt="prev-arrow"
        style={{ width: 25 }}
      />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick, detail } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginRight: "-15px" }}
      onClick={onClick}
    >
      <img
        src={detail ? rightBrownArrow : rightArrow}
        alt="next-arrow"
        style={{ width: 25 }}
      />
    </div>
  );
}

const LibraryCaraosel = ({ detail }) => {
  const navigate = useNavigate();
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow detail={detail} />,
    nextArrow: <NextArrow detail={detail} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onClickNavigate = (path) => {
    navigate(path);
  };

  return (
    <Slider {...settings} className="custom-slider">
      {publicationList.map((item, index) => {
        return (
          <div
            className={`lib-stake-carousel ${detail && "carousel-dark"}`}
            key={index}
            onClick={() => onClickNavigate(item.url)}
          >
            <div className="home-pubs-division-texts">
              <p className="home-pubs-division-title">{item.title}</p>
              <p className="home-pubs-division-desc" style={{ marginTop: 5 }}>
                {item.description}
              </p>
            </div>
            <div className="home-pubs-division-texts-border">
              {item.hashTags.map((_item, _index) => {
                return <p className="project-card-footer-text">#{_item}</p>;
              })}
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default LibraryCaraosel;
